import React from "react";

const Contact = () => {
  return <section></section>;
};

export default Contact;

{
  /* <section id="contactMe" className="container">
        <div className="floatLeft">
          <form className="form">
          <div className="pageTitle title">Let's Connect! </div>
          <div className="secondaryTitle title">
            Let's work together.
          </div> 
          <input type="text" className="name formEntry" placeholder="Name" />
          <input type="text" className="email formEntry" placeholder="Email" />
          <textarea
            className="message formEntry"
            placeholder="Message"
            ></textarea>
          <input type="checkbox" className="termsConditions" value="Term" />
          <label className="terms">
            Captch Captcha.
          </label>
          <button className="submit formEntry">Submit</button>
            </form>
        </div>
      </section> */
}
